import { toast } from "sonner"
import { useAnalyticsStore } from "@/core/stores/analytics"
import { loadScript } from "@/lib/load-script"
import { useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Cookie } from "lucide-react"
import { clarity } from "react-microsoft-clarity"
import mixpanel from "mixpanel-browser"

const GTAG_ID = import.meta.env.PUBLIC_GOOGLE_ANALYTICS_ID
const CLARITY_ID = import.meta.env.PUBLIC_CLARITY_ID
const MIXPANEL_TOKEN = import.meta.env.PUBLIC_MIXPANEL_TOKEN
const HUBSPOT_ID = import.meta.env.PUBLIC_HUBSPOT_ID

// const DEBUG =
//   import.meta.env.PUBLIC_ENV !== "prod" ||
//   JSON.parse(import.meta.env.PUBLIC_DEBUG) ||
//   Number(new URL(window.location.href).searchParams.get("debug")) === 1
const DEBUG = false
export function useConsent() {
  const { displayConsent, updateConsent, updateDisplayConsent } =
    useAnalyticsStore()
  function setConsent(accept: boolean) {
    updateDisplayConsent(false)
    updateConsent(accept)
    window.gtag("consent", "default", {
      ad_personalization: "denied",
      ad_user_data: "denied",
      ad_storage: "denied",
      analytics_storage: accept ? "granted" : "denied",
      functionality_storage: accept ? "granted" : "denied",
      personalization_storage: "denied",
      security_storage: "denied",
    })
  }

  useEffect(() => {
    console.log({
      DEBUG,
      PUBLIC_ENV: import.meta.env.PUBLIC_ENV,
      PUBLIC_DEBUG: import.meta.env.PUBLIC_DEBUG,
      debug: Number(new URL(window.location.href).searchParams.get("debug")),
    })
    if (!DEBUG) {
      loadTracking()
      if (displayConsent) {
        toast("We use cookies!", {
          icon: <Cookie strokeWidth={2} size={16} />,
          description: (
            <>
              This website uses cookies to ensure you get the best experience on
              our website.{" "}
              <a href="/privacy-policy" target="_blank">
                Policy
              </a>
            </>
          ),
          duration: Infinity,
          action: (
            <div className="flex flex-col gap-1 sm:flex-row">
              <Button
                className="border-border focus:outline-none"
                onClick={() => consentAction(true)}
                aria-label="Allow consent"
              >
                Got it
              </Button>
              <Button
                variant={"outline"}
                className="border-none focus:outline-none"
                onClick={() => consentAction(false)}
                aria-label="Disallow consent"
              >
                Nah!
              </Button>
            </div>
          ),
          className: "border-border",
        })
      }
    }
  }, [])
  function consentAction(isConsent: boolean) {
    toast.dismiss()
    setConsent(isConsent)
    if (isConsent) {
      clarity.consent()
    }
  }
  function loadTracking() {
    loadScript(
      `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`,
      function () {
        window.dataLayer = window.dataLayer || []
        window.gtag = function () {
          window.dataLayer.push(arguments)
        }
        gtag("js", new Date())
        gtag("config", GTAG_ID, {
          debug_mode: DEBUG,
        })
        window.gtag = gtag
      },
    )
    loadScript(`https://js.hs-scripts.com/${HUBSPOT_ID}.js`)

    clarity.init(CLARITY_ID)

    mixpanel.init(MIXPANEL_TOKEN, {
      debug: DEBUG,
      track_pageview: true,
      persistence: "localStorage",
    })
    mixpanel.track_links("a", "link.click", {
      referrer: document.referrer,
    })
  }
}

declare global {
  interface Window {
    dataLayer: any | []
  }
}
